"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CachedProvider = void 0;
const iprovider_1 = require("./iprovider");
const leg_1 = require("../models/leg");
/**
 * Provider wrapper that enforces calling the inner provider only once per ticker query.
 */
class CachedProvider extends iprovider_1.IProvider {
    constructor(provider) {
        super({});
        this.cache = { [leg_1.AssetType.STOCK]: new Map(), [leg_1.AssetType.OPTION]: new Map() };
        this.provider = provider;
    }
    /**
     * Convenience method which avoids double-wrapping of an already cached provider.
     * @param provider Provider to wrap for result caching
     * @returns CachedProvider
     */
    static wrap(provider) {
        return provider instanceof CachedProvider ? provider : new CachedProvider(provider);
    }
    quote(tickers) {
        return Promise.all(tickers.map(async (ticker) => {
            const prev = this.cache[leg_1.AssetType.STOCK].get(ticker);
            const curr = prev || this.provider.quote([ticker]).then(arr => arr[0]);
            this.cache[leg_1.AssetType.STOCK].set(ticker, curr);
            return curr;
        }));
    }
    async chain(ticker, expirations) {
        const prev = this.cache[leg_1.AssetType.OPTION].get(ticker);
        const curr = prev || this.provider.chain(ticker);
        this.cache[leg_1.AssetType.OPTION].set(ticker, curr);
        return curr;
    }
    clear(ticker) {
        if (ticker) {
            this.cache.STOCK.delete(ticker);
            this.cache.OPTION.delete(ticker);
        }
        else {
            this.cache.STOCK.clear();
            this.cache.OPTION.clear();
        }
    }
}
exports.CachedProvider = CachedProvider;
