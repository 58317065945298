"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TickerOptions = void 0;
var TickerOptions;
(function (TickerOptions) {
    TickerOptions["NONE"] = "none";
    TickerOptions["INDEX"] = "index";
    TickerOptions["WEEKLY"] = "weekly";
    TickerOptions["MONTHLY"] = "monthly";
    TickerOptions["QUARTERLY"] = "quarterly";
})(TickerOptions = exports.TickerOptions || (exports.TickerOptions = {}));
