"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PortfolioUtils = void 0;
const option_1 = require("../models/option");
const cached_1 = require("../provider/cached");
const stock_utils_1 = require("./stock_utils");
const leg_1 = require("../models/leg");
const YAML = __importStar(require("yaml"));
var PortfolioUtils;
(function (PortfolioUtils) {
    function _priceClosedPosition(position) {
        const sortedLegs = position.Legs.sort((a, b) => a.TimeStamp - b.TimeStamp);
        const returns = sortedLegs.reduce((total, leg) => total + leg.EntryCost, 0);
        const openTime = sortedLegs[0].TimeStamp;
        const closeTime = sortedLegs.slice(-1)[0].TimeStamp;
        return Object.assign({}, position, {
            Returns: returns,
            TimeStamp: closeTime,
            Duration: closeTime - openTime,
        });
    }
    async function _priceOpenPosition(provider, position) {
        // TODO: Move to parameters
        const multiplier = { [leg_1.AssetType.STOCK]: 1, [leg_1.AssetType.OPTION]: 100 };
        const totalContracts = position.Legs.reduce((total, leg) => total + Math.abs(leg.Amount) * multiplier[leg.AssetType], 0);
        // Simplify legs which may be duplicate since there could be more than one buy per leg
        const simpleLegs = [...position.Legs.reduce((map, leg) => {
                const prev = map.get(leg.Id);
                return map.set(leg.Id, {
                    Id: leg.Id,
                    TimeStamp: leg.TimeStamp,
                    AssetId: leg.AssetId,
                    AssetType: leg.AssetType,
                    Amount: leg.Amount + (prev?.Amount ?? 0),
                    EntryCost: leg.EntryCost + (prev?.EntryCost ?? 0),
                });
            }, new Map()).values()];
        // Remove positions which we don't care about since we hold zero contracts of
        const activeLegs = simpleLegs.filter(leg => leg.Amount !== 0);
        const pricedTuples = await Promise.all(activeLegs.map(async (leg) => [leg, await stock_utils_1.StockUtils.priceLeg(provider, leg)]));
        const additiveReducer = (func) => pricedTuples.reduce((total, tuple) => {
            const [leg, _] = tuple;
            const amount = leg.Amount * multiplier[leg.AssetType];
            return total + func(tuple) * amount;
        }, 0);
        const weightedAbsSumReducer = (func) => {
            return pricedTuples.reduce((total, tuple) => {
                const [leg, _] = tuple;
                const amount = Math.abs(leg.Amount) * multiplier[leg.AssetType];
                return total + func(tuple) * amount / totalContracts;
            }, 0);
        };
        return {
            Id: position.Id,
            TimeStamp: position.TimeStamp,
            Legs: pricedTuples.map(([leg, _]) => leg),
            Assets: pricedTuples.map(([_, asset]) => asset),
            EntryCost: position.Legs.reduce((total, leg) => total + leg.EntryCost, 0),
            AskPrice: additiveReducer(([leg, asset]) => {
                if (leg.Amount > 0)
                    return asset.AskPrice;
                if (leg.Amount < 0)
                    return asset.BidPrice;
                else
                    return 0;
            }),
            BidPrice: additiveReducer(([leg, asset]) => {
                if (leg.Amount > 0)
                    return asset.BidPrice;
                if (leg.Amount < 0)
                    return asset.AskPrice;
                else
                    return 0;
            }),
            LastPrice: additiveReducer(([_, asset]) => asset.LastPrice),
            PreviousPrice: additiveReducer(([_, asset]) => asset.PreviousPrice),
            Delta: weightedAbsSumReducer(([_, asset]) => ('Delta' in asset ? asset.Delta : 1)),
            Gamma: weightedAbsSumReducer(([_, asset]) => ('Gamma' in asset ? asset.Gamma : 0)),
            Theta: weightedAbsSumReducer(([_, asset]) => ('Theta' in asset ? asset.Theta : 0)),
            Vega: weightedAbsSumReducer(([_, asset]) => ('Vega' in asset ? asset.Vega : 0)),
            Rho: weightedAbsSumReducer(([_, asset]) => ('Rho' in asset ? asset.Rho : 0)),
        };
    }
    function computePnLxTicks(strikePrices, xsize = 1000) {
        const minStrikePrice = Math.min(...strikePrices);
        const xstep = (Math.max(...strikePrices) - minStrikePrice) / xsize;
        return [...new Set([].concat(
            // Evenly compute P/L across strike prices
            Array.from({ length: xsize }).map((_, idx) => minStrikePrice + xstep * idx), 
            // Add all strike prices to the x axis
            strikePrices))].sort((a, b) => a - b);
    }
    function estimateStockPrice(leg) {
        if (leg.AssetType === leg_1.AssetType.STOCK) {
            return leg.EntryCost / leg.Amount;
        }
        else if (leg.AssetType === leg_1.AssetType.OPTION) {
            const cost = leg.EntryCost / leg.Amount / 100;
            const option = stock_utils_1.StockUtils.parseOCC(leg.Id);
            const strikePrice = option.StrikePrice;
            if (option.Kind === option_1.OptionType.CALL) {
                return strikePrice + cost;
            }
            else if (option.Kind === option_1.OptionType.PUT) {
                return strikePrice - cost;
            }
            else {
                throw new Error(`Unknown option kind: ${option.Kind}`);
            }
        }
        else {
            throw new Error(`Unknown asset type: ${leg.AssetType}`);
        }
    }
    function pricePosition(provider, position) {
        // Create a cached provider to avoid querying the same asset multiple times
        const cachedProvider = cached_1.CachedProvider.wrap(provider);
        // Compute the number of open contracts to determine if the position is still open
        const openContracts = position.Legs.reduce((dict, leg) => dict.set(leg.Id, leg.Amount + (dict.get(leg.Id) || 0)), new Map());
        // If the contracts amount to zero, this is a closed position
        if ([...openContracts.values()].reduce((total, x) => total + Math.abs(x), 0) === 0) {
            return Promise.resolve(_priceClosedPosition(position));
        }
        else {
            return _priceOpenPosition(cachedProvider, position);
        }
    }
    PortfolioUtils.pricePosition = pricePosition;
    function pricePortfolio(provider, positions) {
        // Create a cache to avoid querying the same asset multiple times
        const cachedProvider = cached_1.CachedProvider.wrap(provider);
        return Promise.all(positions.map(pos => pricePosition(cachedProvider, pos)));
    }
    PortfolioUtils.pricePortfolio = pricePortfolio;
    function parsePortfolio(content) {
        const doc = YAML.parse(content);
        return doc.map(position => {
            const symbol = position['Symbol'];
            const legs = position['Trades'].map((trade) => {
                const [amount, strike, expdate, opndate, dollar, price] = trade.split(' ');
                const entryPrice = price.replace(/,/g, '').replace(/\((\d+\.?\d?\d?)\)/, '-$1');
                if (dollar !== '$')
                    throw new Error(`Malformed trade string: "${trade}"`);
                if (strike === 'STOCK') {
                    return {
                        Id: symbol,
                        TimeStamp: Date.parse(opndate),
                        AssetType: leg_1.AssetType.STOCK,
                        AssetId: symbol,
                        Amount: Number(amount),
                        EntryCost: Number(entryPrice),
                    };
                }
                else {
                    const option = {
                        Id: '',
                        Symbol: symbol,
                        Kind: { 'c': option_1.OptionType.CALL, 'p': option_1.OptionType.PUT }[strike.slice(-1)[0]],
                        StrikePrice: Number(strike.slice(0, -1)),
                        ExpirationDate: expdate,
                    };
                    return {
                        Id: stock_utils_1.StockUtils.buildOCC(option),
                        TimeStamp: Date.parse(opndate),
                        AssetType: leg_1.AssetType.OPTION,
                        AssetId: symbol,
                        Amount: Number(amount),
                        EntryCost: Number(entryPrice),
                    };
                }
            });
            return {
                Id: position.Id,
                TimeStamp: Math.min(...legs.map(leg => leg.TimeStamp)),
                Legs: legs,
            };
        });
    }
    PortfolioUtils.parsePortfolio = parsePortfolio;
})(PortfolioUtils = exports.PortfolioUtils || (exports.PortfolioUtils = {}));
