"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INDEX_SYMBOLS = void 0;
exports.INDEX_SYMBOLS = [
    'DJX',
    'MRUT',
    'OEX',
    'RUT',
    'SPX',
    'VIX',
    'XEO',
    'XSP',
];
