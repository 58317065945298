"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestProxy = exports.SwitchStrategy = void 0;
const misc_1 = require("../utils/misc");
const ifetch_1 = require("./ifetch");
const retry_1 = require("./retry");
var SwitchStrategy;
(function (SwitchStrategy) {
    SwitchStrategy[SwitchStrategy["STATIC"] = 0] = "STATIC";
    SwitchStrategy[SwitchStrategy["ROUND_ROBIN"] = 1] = "ROUND_ROBIN";
})(SwitchStrategy = exports.SwitchStrategy || (exports.SwitchStrategy = {}));
class RequestProxy extends ifetch_1.IFetch {
    constructor(proxyUrls, { strategy = SwitchStrategy.ROUND_ROBIN } = {}) {
        super();
        this.idx = -1;
        this.proxyUrls = proxyUrls.map(x => new URL(x));
        this.strategy = strategy;
        this.idx = (0, misc_1.randomInt)(0, this.proxyUrls.length - 1);
    }
    buildUrl(url) {
        const proxyUrl = new URL(this.proxyUrls[this.idx].href);
        proxyUrl.searchParams.set('url', encodeURIComponent(url.toString()));
        if (this.strategy === SwitchStrategy.ROUND_ROBIN) {
            this.idx = (this.idx + 1) % this.proxyUrls.length;
        }
        return proxyUrl;
    }
    async fetch(url, opts = { retries: 3 }) {
        opts.headers = opts.headers || {};
        Object.assign(opts.headers, { origin: 'http://localhost' });
        return retry_1.Retry.fetch(this.buildUrl(url), opts);
    }
}
exports.RequestProxy = RequestProxy;
