"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CandleInterval = void 0;
var CandleInterval;
(function (CandleInterval) {
    CandleInterval[CandleInterval["MIN_1"] = 60] = "MIN_1";
    CandleInterval[CandleInterval["MIN_5"] = 300] = "MIN_5";
    CandleInterval[CandleInterval["HOUR"] = 3600] = "HOUR";
    CandleInterval[CandleInterval["DAY"] = 86400] = "DAY";
})(CandleInterval = exports.CandleInterval || (exports.CandleInterval = {}));
