"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inverseMap = exports.partition = exports.randomInt = exports.shuffle = void 0;
function shuffle(arr) {
    arr = arr.slice();
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
}
exports.shuffle = shuffle;
/**
 * Generate a random integer between min and max
 * @param min lower bound
 * @param max upper bound
 * @return random generated integer
 */
function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
exports.randomInt = randomInt;
function* partition(arr, chunkSize) {
    for (let i = 0; i < Math.ceil(arr.length / chunkSize); i++) {
        const idx0 = i * chunkSize;
        const idx1 = Math.min(idx0 + chunkSize, arr.length);
        yield arr.slice(idx0, idx1);
    }
}
exports.partition = partition;
function inverseMap(dict) {
    return [...dict.entries()].reduce((map, [k, v]) => map.set(v, k), new Map());
}
exports.inverseMap = inverseMap;
