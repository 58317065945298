"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gemini = void 0;
const iprovider_1 = require("./iprovider");
const candle_1 = require("../models/candle");
const API_ROOT = 'https://api.gemini.com/v2';
class Gemini extends iprovider_1.IProvider {
    fetchResults(url) {
        return this.fetcher.fetchJSON(url);
    }
    static intervalToQueryParameter(interval) {
        switch (interval) {
            case candle_1.CandleInterval.DAY:
                return "1day";
            case candle_1.CandleInterval.HOUR:
                return "1hr";
            case candle_1.CandleInterval.MIN_1:
                return "1m";
            case candle_1.CandleInterval.MIN_5:
                return "5m";
            default:
                throw new Error(`Unknown interval: ${interval}`);
        }
    }
    quote(tickers) {
        return Promise.all(tickers.map(async (ticker) => {
            const url = `${API_ROOT}/ticker/${ticker}`;
            const res = await this.fetchResults(url);
            return {
                Id: ticker,
                TimeStamp: Date.now() / 1000,
                PreviousPrice: Number(res.open),
                LastPrice: Number(res.close),
                AskPrice: Number(res.ask),
                BidPrice: Number(res.bid),
                AskSize: NaN,
                BidSize: NaN,
            };
        }));
    }
    async chain(ticker, expirations) {
        throw new Error('Not Implemented');
    }
    async history(ticker, options = {}) {
        options = Object.assign(this.defaultHistoryOptions(), options);
        const ts = new Date().getTime();
        const period = Gemini.intervalToQueryParameter(options.interval);
        const url = `${API_ROOT}/candles/${ticker}/${period}`;
        const res = await this.fetchResults(url);
        return res.map(candle => {
            const [t, o, h, l, c, v] = candle;
            return {
                Id: ticker,
                TimeStamp: ts,
                StartTimeStamp: t,
                EndTimeStamp: t + options.interval * 1000,
                OHLC: [o, h, l, c],
                Volume: v,
            };
        }).filter(candle => candle.EndTimeStamp <= options.dateEnd &&
            candle.StartTimeStamp >= options.dateStart).sort((a, b) => a.StartTimeStamp < b.StartTimeStamp ? -1 : 1);
    }
}
exports.Gemini = Gemini;
