"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PnLUtils = void 0;
const option_1 = require("../models/option");
const stock_utils_1 = require("./stock_utils");
const leg_1 = require("../models/leg");
var PnLUtils;
(function (PnLUtils) {
    function computeOptionPriceAtExpiration(option, stockPrice) {
        switch (option.Kind) {
            case option_1.OptionType.CALL:
                return Math.max(0, stockPrice - option.StrikePrice);
            case option_1.OptionType.PUT:
                return Math.max(0, option.StrikePrice - stockPrice);
            default:
                throw new Error(`Unknown option type: ${option.Kind}`);
        }
    }
    function computeLegPnL(leg, xticks) {
        switch (leg.AssetType) {
            case leg_1.AssetType.STOCK:
                return xticks.map(x => x + leg.EntryCost);
            case leg_1.AssetType.OPTION:
                const mult = leg.Amount * 100;
                const option = stock_utils_1.StockUtils.parseOCC(leg.Id);
                return xticks.map(x => mult * computeOptionPriceAtExpiration(option, x) + leg.EntryCost);
            default:
                throw new Error(`Unknown asset type: ${leg.AssetType}`);
        }
    }
    PnLUtils.computeLegPnL = computeLegPnL;
    function computePositionPnL(position, xticks) {
        // Track the entry cost of the position as a whole
        const entryCost = position.Legs.reduce((total, leg) => total + leg.EntryCost, 0);
        return position.Legs
            .map(leg => computeLegPnL({ ...leg, EntryCost: 0 }, xticks))
            .reduce((posPnL, legPnL) => legPnL.map((pnl, idx) => pnl + (posPnL[idx] || 0)), [])
            .map(pnl => pnl + entryCost);
    }
    PnLUtils.computePositionPnL = computePositionPnL;
})(PnLUtils = exports.PnLUtils || (exports.PnLUtils = {}));
