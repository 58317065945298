"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Yahoo = void 0;
const option_1 = require("../models/option");
const constants_1 = require("../utils/constants");
const convert_1 = require("../utils/convert");
const iprovider_1 = require("./iprovider");
const stock_utils_1 = require("../utils/stock_utils");
const candle_1 = require("../models/candle");
const misc_1 = require("../utils/misc");
const API_ROOT = 'https://query1.finance.yahoo.com';
const TICKER_REPLACE = constants_1.INDEX_SYMBOLS.reduce((map, symbol) => map.set(symbol, `^${symbol}`), new Map());
const TICKER_REPLACE_INVERSE = (0, misc_1.inverseMap)(TICKER_REPLACE);
class Yahoo extends iprovider_1.IProvider {
    async fetchResults(url) {
        const res = await this.fetcher.fetchJSON(url);
        const data = Object.values(res)[0];
        const error = data?.error;
        if (error)
            throw error;
        return data?.result;
    }
    optionBuilder(data, ticker, kind, timestamp) {
        ticker = TICKER_REPLACE_INVERSE.get(ticker) || ticker;
        // Rebuild the ID to ensure symbol consistency (e.g. SPXW vs SPX)
        const option = stock_utils_1.StockUtils.parseOCC(data.contractSymbol);
        const id = stock_utils_1.StockUtils.buildOCC(Object.assign(option, { Symbol: ticker }));
        return {
            Id: id,
            Symbol: ticker,
            Kind: kind,
            TimeStamp: timestamp,
            ExpirationDate: convert_1.Convert.toISODateFormat(convert_1.Convert.toDate(data.expiration)),
            StrikePrice: data.strike,
            OpenInterest: convert_1.Convert.toNumber(data.openInterest, Number.NaN, 0),
            AskPrice: convert_1.Convert.toPrice(data.ask),
            BidPrice: convert_1.Convert.toPrice(data.bid),
            LastPrice: convert_1.Convert.toPrice(data.lastPrice),
            Volatility: convert_1.Convert.toNumber(data.impliedVolatility, Number.NaN, 3),
        };
    }
    async chainSingle(ticker, dateMillis) {
        const ts = new Date().getTime();
        const unixDate = Math.round(dateMillis / 1000);
        const url = `${API_ROOT}/v7/finance/options/${ticker}?date=${unixDate}&timestamp=${ts}`;
        const data = (await this.fetchResults(url))[0];
        const timestamp = data?.quote?.regularMarketTime;
        const options = data?.options[0];
        return [].concat(options?.puts?.map((x) => this.optionBuilder(x, ticker, option_1.OptionType.PUT, timestamp)), options?.calls?.map((x) => this.optionBuilder(x, ticker, option_1.OptionType.CALL, timestamp))).filter(opt => opt.TimeStamp > 0);
    }
    async quote(tickers) {
        tickers = tickers.map(ticker => TICKER_REPLACE.get(ticker) || ticker);
        const ts = new Date().getTime();
        const url = `${API_ROOT}/v7/finance/quote?symbols=${tickers}&timestamp=${ts}`;
        const data = await this.fetchResults(url);
        return data.map(x => ({
            Id: TICKER_REPLACE_INVERSE.get(x.symbol) || x.symbol,
            TimeStamp: x.regularMarketTime,
            AskPrice: convert_1.Convert.toPrice(x.ask),
            BidPrice: convert_1.Convert.toPrice(x.bid),
            AskSize: convert_1.Convert.toNumber(x.askSize, Number.NaN, 0),
            BidSize: convert_1.Convert.toNumber(x.bidSize, Number.NaN, 0),
            LastPrice: convert_1.Convert.toPrice(x.regularMarketPrice),
            PreviousPrice: convert_1.Convert.toPrice(x.regularMarketPreviousClose),
        }));
    }
    async expirationDates(ticker) {
        ticker = TICKER_REPLACE.get(ticker) || ticker;
        const ts = new Date().getTime();
        const url = `${API_ROOT}/v7/finance/options/${ticker}?timestamp=${ts}`;
        const res = await this.fetcher.fetchJSON(url);
        return res?.optionChain?.result[0]?.expirationDates?.map((date) => convert_1.Convert.toISODateFormat(date));
    }
    async chain(ticker, expirations) {
        ticker = TICKER_REPLACE.get(ticker) || ticker;
        expirations = expirations || await this.expirationDates(ticker);
        return stock_utils_1.StockUtils.sortChain([].concat(...await Promise.all(expirations.map(date => this.chainSingle(ticker, convert_1.Convert.toUnixDateFormat(date))))));
    }
    static intervalToQueryParameter(interval) {
        switch (interval) {
            case candle_1.CandleInterval.DAY:
                return "1d";
            case candle_1.CandleInterval.HOUR:
                return "1h";
            case candle_1.CandleInterval.MIN_1:
                return "1m";
            case candle_1.CandleInterval.MIN_5:
                return "5m";
            default:
                throw new Error(`Unknown interval: ${interval}`);
        }
    }
    async history(ticker, options = {}) {
        ticker = TICKER_REPLACE.get(ticker) || ticker;
        options = Object.assign(this.defaultHistoryOptions(), options);
        const ts = new Date().getTime();
        const { dateEnd, dateStart } = options;
        const days = Math.max(1, Math.floor((dateEnd - dateStart) / 1000 / 60 / 60 / 24));
        const url = new URL(`${API_ROOT}/v8/finance/chart/${ticker}`);
        url.searchParams.set('interval', Yahoo.intervalToQueryParameter(options.interval));
        url.searchParams.set('range', `${days}d`);
        const res = await this.fetchResults(url.href);
        const symbol = res[0].meta.symbol || ticker;
        const dates = res[0].timestamp;
        const indicators = res[0].indicators.quote[0];
        const { open, high, low, close, volume } = indicators;
        return dates.map((t, idx) => {
            const [o, h, l, c, v] = [open, high, low, close, volume]
                .map(arr => Math.round(arr[idx] * 100) / 100);
            return {
                Id: TICKER_REPLACE_INVERSE.get(symbol) || symbol,
                TimeStamp: ts,
                StartTimeStamp: t * 1000,
                EndTimeStamp: t * 1000 + options.interval * 1000,
                OHLC: [o, h, l, c],
                Volume: v,
            };
        });
    }
}
exports.Yahoo = Yahoo;
