"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockUtils = void 0;
const leg_1 = require("../models/leg");
const option_1 = require("../models/option");
const time_utils_1 = require("./time_utils");
var StockUtils;
(function (StockUtils) {
    function sortChain(chain) {
        return chain
            .sort((a, b) => a.StrikePrice - b.StrikePrice)
            .sort((a, b) => a.Kind.localeCompare(b.Kind))
            .sort((a, b) => a.ExpirationDate.localeCompare(b.ExpirationDate));
    }
    StockUtils.sortChain = sortChain;
    function parseOCC(occ) {
        // We parse the OCC symbol from right to left. See documentation:
        // https://en.wikipedia.org/wiki/Option_symbol
        // Example: SPX141122P00019500
        const optionId = occ;
        const price = (Number(occ.slice(-8)) / 1000).toFixed(2);
        occ = occ.slice(0, -8);
        const kind = { 'C': option_1.OptionType.CALL, 'P': option_1.OptionType.PUT }[occ.slice(-1)[0]];
        occ = occ.slice(0, -1);
        const yymmdd = occ.slice(-6);
        const yyyy = time_utils_1.TimeUtils.ISODate().substr(0, 2) + yymmdd.slice(0, 2); // Y3K compliant
        occ = occ.slice(0, -6);
        const symbol = occ.slice(0);
        // Convert all the different tokens into an Option object
        return {
            Id: optionId,
            Symbol: symbol,
            Kind: kind,
            StrikePrice: Number(price),
            ExpirationDate: `${yyyy}-${yymmdd.slice(2, 4)}-${yymmdd.slice(4, 6)}`,
        };
    }
    StockUtils.parseOCC = parseOCC;
    function buildOCC(option) {
        const [yyyy, mm, dd] = option.ExpirationDate.split('-', 3);
        const kind = { [option_1.OptionType.CALL]: 'C', [option_1.OptionType.PUT]: 'P' }[option.Kind];
        const price = (option.StrikePrice * 1000).toFixed(0).padStart(8, '0');
        return `${option.Symbol}${yyyy.slice(2, 4)}${mm}${dd}${kind}${price}`;
    }
    StockUtils.buildOCC = buildOCC;
    function serializeLegs(legs) {
        const collapsed = legs.reduce((map, leg) => map.set(leg.Id, leg.Amount + (map.get(leg.Id) || 0)), new Map());
        return [...collapsed.entries()].map(([id, amt]) => `${amt}x${id}`).join(',');
    }
    StockUtils.serializeLegs = serializeLegs;
    function deserializeLegs(legs) {
        return legs.split(',').map(leg => {
            const [amount, id] = leg.split('x', 2);
            const amt = Number(amount);
            if (id.length > 10) {
                const asset = parseOCC(id);
                return { Id: id, AssetId: asset.Symbol, Amount: amt, AssetType: leg_1.AssetType.OPTION };
            }
            else {
                return { Id: id, AssetId: id, Amount: amt, AssetType: leg_1.AssetType.STOCK };
            }
        });
    }
    StockUtils.deserializeLegs = deserializeLegs;
    async function priceLeg(provider, leg) {
        switch (leg.AssetType) {
            case leg_1.AssetType.STOCK:
                return provider.quote([leg.Id]).then(stocks => stocks[0]);
            case leg_1.AssetType.OPTION:
                const occ = StockUtils.parseOCC(leg.Id);
                const chain = await provider.chain(occ.Symbol);
                const option = chain.find(option => option.Kind === occ.Kind &&
                    option.ExpirationDate === occ.ExpirationDate &&
                    Math.abs(option.StrikePrice - occ.StrikePrice) < Number.EPSILON);
                if (!option)
                    throw new Error(`Option ${leg.Id} not found in option chain`);
                return option;
        }
    }
    StockUtils.priceLeg = priceLeg;
})(StockUtils = exports.StockUtils || (exports.StockUtils = {}));
