"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IProvider = void 0;
const candle_1 = require("../models/candle");
class IProvider {
    constructor(fetcher) {
        this.fetcher = fetcher;
    }
    ;
    defaultHistoryOptions() {
        const now = new Date().getTime();
        return {
            dateEnd: now,
            dateStart: now - 365 * 24 * 60 * 60 * 1000,
            interval: candle_1.CandleInterval.DAY,
        };
    }
    async expirationDates(ticker) {
        return [...new Set((await this.chain(ticker)).map(option => option.ExpirationDate))];
    }
    async history(ticker, options = {}) {
        throw new Error('Not Implemented');
    }
}
exports.IProvider = IProvider;
