"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Convert = void 0;
var Convert;
(function (Convert) {
    function toNumber(num, fallback = Number.NaN, precision = Number.NaN) {
        num = num ? Number(num) : fallback;
        if (precision === 0) {
            num = Math.round(num);
        }
        else if (!Number.isNaN(precision)) {
            const mult = Math.pow(10, precision);
            num = Math.round((num + Number.EPSILON) * mult) / mult;
        }
        return num;
    }
    Convert.toNumber = toNumber;
    function toDate(num, seconds = true) {
        if (seconds)
            num *= 1000;
        return new Date(num);
    }
    Convert.toDate = toDate;
    function toPrice(num) {
        return Convert.toNumber(num, 0, 2);
    }
    Convert.toPrice = toPrice;
    function toISODateFormat(date, seconds = true) {
        if (typeof date === 'number')
            date = Convert.toDate(date, seconds = seconds);
        let dateString = `${date.getFullYear()}-`;
        dateString += `${date.getMonth() + 1}`.padStart(2, '0') + '-';
        dateString += `${date.getDate()}`.padStart(2, '0');
        return dateString;
    }
    Convert.toISODateFormat = toISODateFormat;
    function fromISODateFormat(date) {
        return Convert.toDate(Date.parse(date));
    }
    Convert.fromISODateFormat = fromISODateFormat;
    function toUnixDateFormat(date, seconds = true) {
        if (typeof date === 'string')
            date = Convert.fromISODateFormat(date);
        let num = date.getTime();
        if (seconds)
            num /= 1000;
        return num;
    }
    Convert.toUnixDateFormat = toUnixDateFormat;
})(Convert = exports.Convert || (exports.Convert = {}));
