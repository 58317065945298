"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GBear = void 0;
const iprovider_1 = require("./iprovider");
const API_ROOT = 'https://api.gbear.trade';
class GBear extends iprovider_1.IProvider {
    fetchResults(url) {
        return this.fetcher.fetchJSON(url);
    }
    quote(tickers) {
        return Promise.all(tickers.map(async (ticker) => {
            const url = `${API_ROOT}/stock/${ticker}.json`;
            const res = await this.fetchResults(url);
            return res.data;
        }));
    }
    async chain(ticker, expirations) {
        const url = `${API_ROOT}/chain/${ticker}.json`;
        const res = await this.fetchResults(url);
        return res.data;
    }
}
exports.GBear = GBear;
