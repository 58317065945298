"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeUtils = void 0;
const convert_1 = require("./convert");
var TimeUtils;
(function (TimeUtils) {
    function ISODate(offsetSeconds = 0, timezone = 'EST') {
        const date = new Date(new Date().toLocaleString("en-US", { timeZone: timezone }));
        if (offsetSeconds !== 0)
            date.setTime(date.getTime() + offsetSeconds * 1000);
        return convert_1.Convert.toISODateFormat(date);
    }
    TimeUtils.ISODate = ISODate;
})(TimeUtils = exports.TimeUtils || (exports.TimeUtils = {}));
